<ion-app>
  <ion-split-pane when="xl" contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header>Convator App</ion-list-header>
          <ion-note>v.{{global.version}}</ion-note>

          <ion-menu-toggle auto-hide="false">
            <ion-item routerDirection="root" routerLink="home" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="'home-outline'" [md]="'home-sharp'"></ion-icon>
              <ion-label>Home</ion-label>
            </ion-item>
            <ion-item routerDirection="root" routerLink="logbook" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="'alarm-outline'" [md]="'alarm-sharp'"></ion-icon>
              <ion-label>Logboek</ion-label>
            </ion-item>
            <ion-item routerDirection="root" routerLink="iolist" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="'list-outline'" [md]="'list-sharp'"></ion-icon>
              <ion-label>IO list</ion-label>
            </ion-item>
            <ion-item routerDirection="root" routerLink="liftsettings" lines="none" detail="false" routerLinkActive="selected">
                <ion-icon slot="start" [ios]="'build-outline'" [md]="'build-sharp'"></ion-icon>
                <ion-label>Instellingen Lift</ion-label>
            </ion-item>
            <ion-item routerDirection="root" routerLink="settings" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="'settings-outline'" [md]="'settings-sharp'"></ion-icon>
              <ion-label>Instellingen App</ion-label>
            </ion-item>
            <ion-item routerDirection="root" routerLink="about" lines="none" detail="false" routerLinkActive="selected">
                <ion-icon slot="start" [ios]="'information-circle-outline'" [md]="'information-circle-sharp'"></ion-icon>
                <ion-label>About</ion-label>
              </ion-item>
            </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
