import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
    public version = '0.1.0';
    // zie ook android\app\build.gradle

    public resetButton = false;
    public userLevel = 0;

    public comm = {
        type: 'DIRECT',
        ip: '192.168.135.10',
        port: '8035',
        usr: '',
        pwd: '',
    };

    private activityTimer: any;

    constructor() {
        const ip = localStorage.getItem('ip');
        if (ip) {
            this.comm.ip = ip;
        }
        const port = localStorage.getItem('port');
        if (port) {
            this.comm.port = port;
        }
    }

    public updateActivity() {
        if (this.activityTimer) {
            clearTimeout(this.activityTimer);
        }
        this.activityTimer = setTimeout(() => {this.userLevel = 0;}, 10 * 60 * 1000); // na X minuten inactiviteit uitloggen
    }
}
